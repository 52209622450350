<template>
  <div>
    <div class="mb-5">
      <h1 class="mt-0 fs-2500 fs-b pt-2">Sélection de l'établissement</h1>
      <p>
        Vous disposez d’habilitations sur plusieurs établissements de
        l'entreprise {{ rs }} ({{ siren }}). Veuillez sélectionner celui pour
        lequel vous souhaitez agir.
      </p>
    </div>
    <div class="row fr-container">
      <div class="fr-search-bar fr-search-bar--lg" id="search-input--lg">
        <label class="fr-label" for="search-input--lg-input"
          >Numéro Siret de l'établissement</label
        >
        <div class="fr-input">
          <span class="siren">{{ siren }}</span>
          <input
            placeholder="Compléter le SIRET"
            type="search"
            id="search-input--lg-input"
            name="search-input--lg-input"
            v-model="search"
            maxlength="5"
            @input="onInput"
            class="search"
            :aria-describedby="siren"
          />
        </div>
        <button
          class="fr-btn fr-btn--lg"
          title="Rechercher"
          :disabled="disableSearch()"
          @click="
            doSearch();
            tagEtablissement('rechercher');
          "
          aria-label="rechercher un établissement"
        >
          <span> Rechercher </span>
        </button>
      </div>
      <div class="fr-table fr-table--bordered">
        <table>
          <caption>
            Liste des établissements
          </caption>
          <thead>
            <tr>
              <th scope="col">Siret ou équivalent</th>
              <th scope="col" v-show="false">Catégorie URSSAF</th>
              <th scope="col">Raison sociale de l'établissement</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="table-line mb-3 select-entreprise"
              v-for="item in searchedEtablissementsByPage"
              :key="item.siret"
            >
              <td @click="selectEtablissement(item)">
                <span>{{ $filters.sirenFormat(item.siret) }}</span>
              </td>
              <td v-show="false">
                {{ item.categorie }}
              </td>
              <td>
                <button
                  @click="selectEtablissement(item)"
                  class="select-etablissement fr-link fr-name-tag"
                  title="Cliquer ici pour selectionner un établissement"
                  ref="derouler"
                >
                  <span class="color-label float-left">
                    {{ item.raisonSocialeEtablissement }}
                  </span>
                  <span v-if="item.is_siege">&Eacute;tablissement siège</span>
                  <i
                    class="float-right ri-arrow-right-line ri-xl ml-5 color-pr"
                    style="margin-top: 1%"
                  ></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="chargerPlus">
          <button
            type="button"
            class="fr-btn mt-3"
            @click="showMore"
            v-if="showSearch()"
            title="Cliquer ici pour charger plus d'établissements"
          >
            Charger plus d'établissements
          </button>
        </div>
      </div>
    </div>

    <CertificationProconnectModal
      v-if="showModalCerti"
      @close="closeAll()"
      @closeAndOpen="closeModalProconnect()"
    />
    <CertificationProconnectSirenInconnuModal
      v-if="showModalCertifSirenInconnu"
      @close="closeModalSirenInconnu()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import store from '../store';

import CertificationProconnectModal from '../components/CertificationProconnectModal';
import CertificationProconnectSirenInconnuModal from '../components/CertificationProconnectSirenInconnuModal';
import { URSSAF } from '../constantes';
import SkipLinkMixin from '../mixins/skip-link-mixin';
import { sendTag } from '../utils/methodsUtils';
import { getRouteNameFromPath } from '../utils/navigationUtils';

import routes from '../router/routes';

export default {
  name: 'EtablissementSelector',
  components: {
    CertificationProconnectModal,
    CertificationProconnectSirenInconnuModal
  },
  mixins: [SkipLinkMixin],
  data() {
    return {
      search: '',
      searchedEtablissements: [],
      page: 1,
      showModalCerti: false,
      showModalCertifSirenInconnu: false,
      routesDetail: routes[0].children.filter(
        (e) =>
          e.meta &&
          e.meta.breadcrumb &&
          (e.meta.breadcrumb[0].link == 'paiements' ||
            e.meta.breadcrumb[0].link == 'declarations')
      ),
      cible: '',
      nextStep: ''
    };
  },
  computed: {
    ...mapGetters(['siren', 'etablissements', 'rs', 'getRouteData']),
    searchedEtablissementsByPage() {
      return this.searchedEtablissements.slice(0, 10 * this.page);
    }
  },
  methods: {
    ...mapActions(['setEtablissement', 'showPopup', 'setContent', 'setAGE']),
    derouler(a) {
      this.$refs['derouler'][a].focus();
    },
    showSearch() {
      return (
        this.searchedEtablissements.length >
        this.searchedEtablissementsByPage.length
      );
    },
    disableSearch() {
      return !this.search;
    },
    onInput(e) {
      const {
        target: { value }
      } = e;

      if (!value.match(/^[\w\d]+$/)) {
        this.search = this.search.slice(0, -1);
      }
      if (!this.search) {
        this.searchedEtablissements = this.etablissements;
        this.page = 1;
      }
    },
    doSearch() {
      if (this.search) {
        this.searchedEtablissements = this.etablissements.filter((e) =>
          e.siret.substring(e.siret.length - 5).startsWith(this.search)
        );
      } else {
        this.searchedEtablissements = this.etablissements;
      }
      this.page = 1;
    },
    showMore() {
      this.page = this.page + 1;
      setTimeout(() => {
        this.derouler((this.page - 1) * 10);
      }, 0);
    },
    selectEtablissement(etablissement) {
      if (
        store.state.compte.previousPath.includes('/cds') &&
        this.cible &&
        this.cible.length
      ) {
        this.setEtablissement(etablissement);
        let cible = this.cible.replace('<SIRET>', etablissement.siret);

        window.location.href = cible;
      } else {
        if (
          !(
            this.nextStep &&
            (store.state.compte.previousPath.includes('mandats') ||
              store.state.compte.previousPath.includes('declarations'))
          )
        ) {
          this.setEtablissement(etablissement);
        }
        if (this.nextStep) {
          if (
            store.state.compte.previousPath.includes('mandats') ||
            store.state.compte.previousPath.includes('declarations')
          ) {
            store.dispatch('setPreviousPath', '');
          }
          let cible = this.cible.replace('<siret>', etablissement.siret);
          cible = cible.replace('<categorie>', etablissement.categorie);
          window.location.href = cible;
        } else {
          if (store.state.compte.previousPath) {
            let path = store.state.compte.previousPath;
            store.dispatch('setPreviousPath', '');
            path = this.pushToRightPath(path);
            const routeName = getRouteNameFromPath(path, this.$router);
            this.$router.push({ name: routeName }).catch(() => {});
          } else {
            this.$router.push({ name: 'Tableau de bord' }).catch(() => {});
          }
        }
      }
    },
    verificationProConnect() {
      const isProConnectKO = localStorage.getItem('isProConnectKO');
      const isProConnectKOEtablissementSelector = localStorage.getItem(
        'isProConnectKOEtablissementSelector'
      );
      if (
        isProConnectKO === 'true' &&
        isProConnectKOEtablissementSelector === 'true'
      ) {
        this.isHabilite(store.state.compte.siren).then(async (res) => {
          if (res > 0 && this.etablissements.length >= 5) {
            const messageErreur =
              "<p class='fs-875'>Vous n'êtes plus considéré comme dirigeant de cette entreprise par ProConnect. </br> Nous vous retirons le rôle AGE ainsi que les attributions liées à ce rôle.</p>";
            this.toggleErreurConfirmation(messageErreur);
          }
        });
        localStorage.setItem('isProConnectKOEtablissementSelector', false);
      }
    },
    async isHabilite(siren) {
      return new Promise((resolve) => {
        const habilitationRequest = {
          siren,
          subModificateur: ''
        };
        this.$habilitationService
          .getAllHabilitationList(habilitationRequest)
          .then((response) => {
            const list = response.data.habilitations;
            store.dispatch('setHabilitations', list);
            const listHabilitationFiltred = list.filter(
              ({ copro }) => copro === URSSAF.codeCopro
            );
            resolve(listHabilitationFiltred.length);
          });
      });
    },

    toggleErreurConfirmation(message) {
      this.setAGE(false);
      this.setContent({ html: message, action: 'info' });
      this.showPopup();
      this.emitter.once('popup', (payload) => {
        if (payload === 'ok' || payload === 'hide') {
          const routeName = getRouteNameFromPath(this.pathParent, this.$router);
          this.$router.push({ name: routeName }).catch(() => {});
        }
      });
    },
    showModalProconnect() {
      this.showModalCerti = true;
      this.closeModalSirenInconnu();
    },
    closeModalProconnect() {
      this.showModalCerti = false;
      this.showModalSirenInconnu();
    },
    showModalSirenInconnu() {
      this.showModalCertifSirenInconnu = true;
    },
    closeModalSirenInconnu() {
      this.showModalCertifSirenInconnu = false;
    },
    closeAll() {
      this.showModalCerti = false;
      this.showModalCertifSirenInconnu = false;
    },
    reloadHeader() {
      if (window.screen.width <= 767) {
        this.emitter.emit('rafraichir-header');
      }
    },
    tagEtablissement(tagName) {
      sendTag(tagName, 'recherche_siret', '', '');
    },
    pushToRightPath(path) {
      let previousRoute = this.routesDetail.filter(
        (e) => e.path == path.replace('/', '')
      );
      if (
        previousRoute &&
        previousRoute[0] &&
        !previousRoute[0].path.includes('avis_de_taxe_fonciere') &&
        !previousRoute[0].path.includes('mandats')
      ) {
        return previousRoute[0].meta.breadcrumb[0].link;
      }
      return path;
    }
  },
  mounted() {
    if (this.getRouteData && this.getRouteData.cible) {
      this.cible = this.getRouteData.cible;
      this.nextStep = this.getRouteData.nextStep;
    }
    if (this.getRouteData && this.getRouteData.nextStep) {
      this.nextStep = this.getRouteData.nextStep;
    }

    this.searchedEtablissements = this.etablissements;
    this.verificationProConnect();
  },
  unmounted() {
    this.reloadHeader();
  }
};
</script>
<style lang="scss" scoped>
span {
  font-family: 'Marianne';
  font-size: 0.975rem;
  text-align: left;
  padding: 10px 0;
  z-index: 1;
}
.fr-search-bar .fr-btn::before {
  z-index: 1;
}
.fr-search-bar .fr-input {
  box-shadow: inset 0 -2px 0 0 $base-color;
  padding-bottom: 0;
  padding-top: 12px;
}

.siren {
  font-weight: bold;
  padding: 0 10px 0 0;
  margin-right: 10px;
}

.search {
  border: 0;
  background: none;
}

.fr-search-bar {
  width: auto;
  margin: auto;
  //width: 60%;
  z-index: 1;
}
.chargerPlus {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 50px;

  button {
    font-weight: inherit;
  }
}

.fr-btn:not(.fr-btn--secondary) {
  background: $base-color;
}
.fr-btn:hover {
  background: #1212ff;
}
.fr-btn--lg {
  height: 50px;
  font-weight: inherit;
}
table {
  margin-top: 15px !important;
  width: 65%;
  margin: auto;
  display: inline-table;
  caption {
    margin-top: 15px !important;
  }
}

tbody {
  background-color: transparent;
}
h2,
h1 {
  color: $base-color;
}
.button-plus {
  width: 25%;
}

.button.fr-btn[disabled],
button.fr-btn:disabled {
  background: $base-color-hover;
  color: #ffffff;
}
.select-entreprise:hover {
  cursor: pointer;
}

.fr-fi-information-line {
  margin-left: 1.5%;
}
.fr-fi-information-line:hover {
  cursor: pointer;
  transform: scale(1.1);
  color: blue;
}

.fr-table {
  margin: auto;
}

table td {
  font-size: 0.875rem;
  padding: 0;
}
td:first-child {
  padding-left: 1rem;
}

.select-etablissement {
  width: 100%;
  padding: 1rem;
  display: inline;
  border-radius: 0;
}

.select-entreprise {
  span {
    font-size: 0.875rem;
    padding: 0;
  }
}

#search-input--lg-input:focus {
  outline: solid 2px #2a7ffe;
}

@media only screen and (max-width: 401px) {
  #search-input--lg-input {
    position: relative;
    top: -15px;
  }
}

@media only screen and (max-width: 760px) and (min-width: 402px) {
  #search-input--lg-input {
    position: relative;
    top: 5px;
  }
}

@media only screen and (max-width: 760px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    padding-top: 10px;
  }

  td {
    /* Behave  like a "row" */
    position: relative;
  }

  td:first-child {
    padding-bottom: 1.5rem;
  }

  td:before {
    position: absolute;
    top: 0;
    left: 15px;
    width: 45%;
    white-space: nowrap;
  }

  td span {
    position: relative;
    text-align: left;
    top: 15px;
    width: 100%;
    white-space: nowrap;
    margin-bottom: 0;
    font-size: 0.875rem;
  }

  .color-label {
    left: 15px;
  }

  .select-etablissement {
    padding-left: 0;
    padding-top: 0;
  }

  /*
		Label the data
		*/
  td:nth-of-type(1):before {
    content: 'Siret ou équivalent';
    color: gray;
    font-size: small;
  }
  td:nth-of-type(3):before {
    content: "Raison sociale de l'établissement";
    color: gray;
    font-size: small;
  }

  .row {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  tr {
    border: 1px solid #cecece;
    border-left-color: $base-color !important;
    border-left-width: thick;
  }

  .fr-table--bordered tbody td,
  .fr-table--bordered tbody th {
    border-bottom: 0;
  }

  .siren {
    position: relative;
    left: 44%;
    top: -80%;
  }

  .fr-input {
    padding-top: 0 !important;
  }

  #search-input--lg {
    max-height: 2.5rem;
  }

  .fr-btn--lg {
    min-height: 2.5rem;
  }
}

@media only screen and (min-width: 760px) {
  .row {
    width: 78%;
    margin: auto;
  }
  .color-label {
    color: $base-color;
  }
  .siren {
    border-right: 1px solid #ddd;
  }
}
</style>
